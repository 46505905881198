<template>
  <base-section id="reliable">
    <base-section-heading
      title="SPECIALTY CHEMICAL PROVIDER"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card
            v-bind="card"
            align="center"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionReliable',

    data: () => ({
      cards: [
        {
          title: 'Quality Guaranteed',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-certificate',
        },
        {
          title: 'competitive prices',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-currency-usd',
        },
        {
          title: 'wide product range',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-lightbulb-on-outline',
        },
      ],
    }),
  }
</script>
